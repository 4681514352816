@import 'Assets/styles/colors.scss';

#product_order_items_container_part_5 {
  .is-focusing {
    background-color: $table_hover_background;
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: none;
    width: 100%;
    overflow-x: scroll;
  }
  .inner-content {
    width: 100%;
    min-width: 700px;
    border: 1px solid #cccccc;
    border-bottom: none;
  }
  .card-wrapper {
    width: 100%;
    padding: 0px;
    box-shadow: none;
  }
  .row-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0px;
  }
  .row-item {
    flex: 1;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    padding: 10px;

    &:last-child {
      // max-width: 80px;
      border-right: none;
      border-bottom: 1px solid #cccccc;
    }
  }
  .thread-row {
    background-color: #fafafa;
    font-weight: 500;
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .add-more-button {
    border: none;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    text-shadow: none;
    background-color: #0097c4;
  }
  .total-wrapper {
    margin-top: 20px;
  }
  .total-number {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  .total-number-title {
    font-size: 14px;
    color: #474747;
    margin-bottom: 10px;
    // marginRight: 20;
    width: 80px;
  }
  .total-number-content {
    font-size: 14px;
    color: #474747;
  }
}
