@import 'Assets/styles/colors.scss';

.other-documents {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .order-table-title {
    font-size: 14px;
    color: $title_color;
    margin: 0;
    height: 100%;
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
  }

  .order-table-content {
    width: 100%;
    display: flex;
    flex-direction: column;

    .inner-content {
      border-bottom: none;
      overflow-x: scroll;

      .scroll-wrap {
        width: 100%;
        min-width: 550px;
        max-width: 600px;
        border: 1px solid $table_border_color;
        border-bottom: none;

        .row-wrapper {
          display: flex;
          width: 100%;
          height: 100%;
          padding: 0;

          .row-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            border-right: 1px solid $table_border_color;
            border-bottom: 1px solid $table_border_color;
            padding: 10px;
            word-wrap: break-word;

            &:last-child {
              border-right: none;
            }
          }
          .thread-row {
            background-color: $fourth_background;
            font-weight: 500;
          }
          .last-child {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            padding: 10px;
            border-bottom: 1px solid $table_border_color;
            word-wrap: break-word;
          }
        }
      }
    }
  }
}

.producing-button-container {
  display: flex;
  align-items: center;
  margin-top: 40px;
  max-width: 300px;

  .cancel-button {
    border: none;
    background-color: $back_button_color;
    width: 100%;
    max-width: 130px;
    margin-right: 15px;

    span {
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      color: $cancel_button_text_color;
      text-shadow: none;
    }
  }

  .process-button {
    border: none;
    width: 100%;
    max-width: 130px;
    span {
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      text-shadow: none;
    }
  }
}
