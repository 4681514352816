// background
$primary_color: #0097c4;
$secondary_background: #000000;
$third_background: #f0f2f5;
$fourth_background: #FAFAFA;
$background_default: #ffffff;
$left_side_menu_logo_background: #002140;
$table_hover_background: #daf1f8d7;
$transparent_loading_background: rgba(255,255,255,0.5);

// text
$primary_text_color: #0097c4;
$default_text_color: #ffffff;
$header_text_color: #585858;
$title_color: #474747;
$part_title_color: #096885;
$cancel_button_text_color: #000000a6;
$view_button_text_color: #148d1a;
$update_button_text_color: #097b9e;
$duplicate_button_text_color: chocolate;
$delete_button_text_color: #ec2020;
$compulsory_field_symbol_color: #ec2020;

// button background
$back_button_color: #0000000f;
$active_button_color: #6ba95d;
$deactive_button_color: #f0af46;
$delete_button_color: #ff4d4f;
$update_button_color: #0097c4;
$duplicate_button_color: chocolate;
$product_button_color: #148d1a;
$producing_button_color: #D07540;

// border
$table_border_color: #CCCCCC