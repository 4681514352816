@import 'Assets/styles/colors.scss';

#edit_supplier_debt_invoice_page {
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 10px 5%;
  width: 100%;

  .page-title {
    font-size: 30px;
    margin: 0;
    margin-bottom: 30px;
    color: $title_color;
  }

  .general-information {
    margin-bottom: 40px;

    .part-title {
      font-size: 20px;
      font-weight: 600;
      color: $part_title_color;
      margin: 0;
      margin-bottom: 20px;
    }

    .part-field-wrapper {
      padding-left: 10px;
    }
  }
}
